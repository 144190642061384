import BreadCrumb from 'src/Components/Common/BreadCrumb'
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import MetaBar from 'src/Components/Common/MetaBar'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  CallListRequest,
  Collector,
  PhonesPriority,
  useCreateCallListMutation,
  useGetCallListQuery,
  useGetSegmentsQuery,
  useUpdateCallListMutation
} from 'src/services/calling'
import { CollectorsGroup } from '../Queues/Components/collectorsGroup'
import LoadingPage from '../LoadingPage'
import ErrorPage, { DangerAlert } from '../ErrorPage'
import Select from 'react-select'
import { useFormik } from 'formik'
import { getCallListValidationSchema } from './schema/call-list-schema'
import { useLoadCollectors } from './hooks/useLoadCollectors'
import InputItem from '../Queues/InputItem'
import { DayOption, FormValues, SegmentOption } from './types/callList.types'
import { getPhoneTypes, inputInitials } from './constants/callList.constants'
import { constructSortRules, getInitialValues } from './utils/callList.utils'

export const EditCallList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const { collectorsData, loading, errorGroupCollectors, errorGroups } =
    useLoadCollectors()
  const {
    data: stagesData,
    error: errorSegments,
    isLoading: isLoadingSegments
  } = useGetSegmentsQuery()

  const {
    data: callListData,
    error: errorCallList,
    isLoading: isLoadingCallList
  } = useGetCallListQuery(id ?? '', { skip: !id })

  const [
    createCallList,
    { isLoading: isLoadingCreateCallList, error: errorCreateCallList }
  ] = useCreateCallListMutation()
  const [
    updateCallList,
    { isLoading: isLoadingUpdateCallList, error: errorUpdateCallList }
  ] = useUpdateCallListMutation()

  const validation = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: getInitialValues(callListData, collectorsData),
    validationSchema: getCallListValidationSchema(t),
    onSubmit: async (values) => {
      const {
        name,
        stages,
        users,
        is_federal_law_limited,
        is_active,
        phones_priority,
        recall_weekdays,
        extra_settings
      } = values
      const finalRecallWeekdays = recall_weekdays
        ? Object.fromEntries(
            Object.entries(recall_weekdays).map(([k, v]) => [
              String(k),
              Number(v)
            ])
          )
        : null

      const sort_rules = constructSortRules(values)
      const userIds = users.map((user) => user.id)
      const callListRequest: CallListRequest = {
        name,
        stages,
        phones_priority,
        is_federal_law_limited,
        is_active,
        sort_rules,
        users: userIds,
        recall_weekdays: finalRecallWeekdays,
        extra_settings
      }
      if (id) {
        try {
          await updateCallList({ id, ...callListRequest }).unwrap()
          navigate('/call-lists')
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          await createCallList(callListRequest).unwrap()
          navigate('/call-lists')
        } catch (error) {
          console.log(error)
        }
      }
    }
  })
  const formattedData: SegmentOption[] =
    stagesData?.map((option) => ({
      value: option.id,
      label: option.name
    })) || []

  const onChangeCollector = (collector: Collector) => {
    const selected = validation.values.users.some((c) => c.id === collector.id)
    const updatedCollectors = selected
      ? validation.values.users.filter((c) => c.id !== collector.id)
      : [...validation.values.users, collector]
    validation.setFieldValue('users', updatedCollectors)
  }

  const setCollectorsByStage = (updatedCollectors: Collector[]) => {
    validation.setFieldValue('users', updatedCollectors)
  }

  const title = `${t('Formation_of_Call-lists')}: ${
    id ? t('Edit') : t('Create')
  }`
  document.title = title

  if (loading || isLoadingSegments || isLoadingCallList) {
    return <LoadingPage />
  }

  if (errorCallList) {
    return <ErrorPage title={title} />
  }

  const dayNames = [
    t('Monday'),
    t('Tuesday'),
    t('Wednesday'),
    t('Thursday'),
    t('Friday'),
    t('Saturday'),
    t('Sunday')
  ]

  const dayOptions: DayOption[] = [
    { value: '', label: t('Day_of_the_week'), disabled: true },
    ...dayNames.map((dayName, index) => ({
      value: index,
      label: dayName
    }))
  ]

  return (
    <div className='page-content'>
      <BreadCrumb title={title} />
      <Card>
        <CardBody>
          <MetaBar backLink={'/call-lists'} />
          <form onSubmit={validation.handleSubmit}>
            <div className='mt-3 mb-3 w-100' style={{ maxWidth: 300 }}>
              <Label htmlFor={'name'}>{t('name')}</Label>
              <Input
                type='text'
                name='name'
                className='form-control form-control-sm'
                id='name'
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name}
                invalid={!!(validation.touched.name && validation.errors.name)}
              />
              {validation.touched.name && validation.errors.name ? (
                <div className='invalid-feedback'>{validation.errors.name}</div>
              ) : null}
            </div>
            <FormGroup className='d-flex gap-2'>
              <Label for='is_federal_law_limited'>
                {t('Apply_Federal_Law_230_restrictions_on_calls')}
              </Label>
              <Input
                id='is_federal_law_limited'
                name='is_federal_law_limited'
                type='checkbox'
                checked={validation.values.is_federal_law_limited}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={!!validation.errors.is_federal_law_limited}
              />
            </FormGroup>
            <FormGroup className='d-flex gap-2'>
              <Label for='is_active'>{t('is_active')}</Label>
              <Input
                id='is_active'
                name='is_active'
                type='checkbox'
                checked={validation.values.is_active}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={!!validation.errors.is_active}
              />
            </FormGroup>
            <FormGroup className='d-flex gap-2 align-items-center'>
              <div style={{ marginRight: '20px' }}>{`id ${t('Queues')}`}</div>
              <div className='d-flex flex-column'>
                <Input
                  id='extra_settings'
                  name='extra_settings.queue_id'
                  type='number'
                  value={validation.values.extra_settings?.queue_id || ''}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  style={{ maxWidth: 80 }}
                  className='form-control form-control-sm'
                />
              </div>
            </FormGroup>
            <div className='mt-3 mb-3 w-100' style={{ maxWidth: 300 }}>
              <Label>{t('Segments')}</Label>
              <Select
                isMulti
                options={formattedData}
                name='stages'
                value={formattedData.filter((option) =>
                  validation.values.stages.includes(option.value)
                )}
                onChange={(selectedOptions) => {
                  const valuesArray = selectedOptions.map(
                    (option) => option.value
                  )
                  validation.setFieldValue('stages', valuesArray)
                }}
                onBlur={validation.handleBlur}
                getOptionValue={(option) => option.value.toString()}
                getOptionLabel={(option) => option.label}
              />
            </div>
            {!!errorSegments && <DangerAlert error={errorSegments} />}
            <div className={'mb-4 mt-4'}>
              <h4>{t('Prioritizing_phone_numbers_for_calling')}</h4>
              {getPhoneTypes(t).map(({ label, name }) => {
                const fieldNameParts = name.split('.')
                const fieldKey = fieldNameParts[fieldNameParts.length - 1]

                const error =
                  validation.errors.phones_priority &&
                  typeof validation.errors.phones_priority === 'object'
                    ? validation.errors.phones_priority[fieldKey]
                    : null

                const touched =
                  validation.touched.phones_priority &&
                  typeof validation.touched.phones_priority === 'object'
                    ? validation.touched.phones_priority[fieldKey]
                    : null
                return (
                  <FormGroup
                    key={name}
                    className='d-flex gap-2 align-items-center'
                  >
                    <div style={{ marginRight: '20px', minWidth: '210px' }}>
                      {label}
                    </div>
                    <div className='d-flex flex-column'>
                      <Label htmlFor={name}>{t('product_priority')}</Label>
                      <Input
                        id={name}
                        name={name}
                        type='number'
                        value={
                          validation.values.phones_priority?.[
                            fieldKey as keyof PhonesPriority
                          ] ?? ''
                        }
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={!!(touched && error)}
                        style={{ maxWidth: 80 }}
                        className='form-control form-control-sm'
                      />
                      {touched && error && (
                        <div className='invalid-feedback'>{error}</div>
                      )}
                    </div>
                  </FormGroup>
                )
              })}
            </div>
            <div className={'mb-4 mt-4'}>
              <h4>{t('Days_of_the_week')}</h4>
              {dayNames.map((dayName, index) => {
                const fieldName = `recall_weekdays.${index}`
                const error =
                  validation.errors.recall_weekdays &&
                  typeof validation.errors.recall_weekdays === 'object'
                    ? validation.errors.recall_weekdays[String(index)]
                    : null
                const touched =
                  validation.touched.recall_weekdays &&
                  typeof validation.touched.recall_weekdays === 'object'
                    ? validation.touched.recall_weekdays[String(index)]
                    : null

                return (
                  <FormGroup
                    key={index}
                    className='d-flex gap-2 align-items-center'
                  >
                    <div style={{ marginRight: '20px', minWidth: '210px' }}>
                      {dayName}
                    </div>
                    <div className='d-flex flex-column'>
                      <select
                        id={fieldName}
                        name={fieldName}
                        value={
                          validation.values.recall_weekdays?.[String(index)] ??
                          ''
                        }
                        onChange={(e) =>
                          validation.setFieldValue(
                            fieldName,
                            Number(e.target.value)
                          )
                        }
                        onBlur={validation.handleBlur}
                        className={`form-control form-control-sm ${
                          touched && error ? 'is-invalid' : ''
                        }`}
                      >
                        {dayOptions.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {touched && error && (
                        <div className='invalid-feedback'>{error}</div>
                      )}
                    </div>
                  </FormGroup>
                )
              })}
            </div>
            <div className={'mb-4 mt-4'}>
              <h4>{t('Sorting')}</h4>
              <div className='mt-3 mb-3'>
                {Object.keys(inputInitials)
                  .filter((d) => inputInitials[d].type === 'order')
                  .map((fieldKey) => {
                    const keyOrder = `sort_rules_${fieldKey}_order`
                    const keyPriority = `sort_rules_${fieldKey}_priority`
                    return (
                      <Row key={fieldKey}>
                        <Col className='col-6' style={{ maxWidth: 300 }}>
                          <InputItem
                            description={inputInitials[fieldKey].order!}
                            validation={validation}
                            validationKey={keyOrder}
                          />
                        </Col>
                        <Col className='col-6' style={{ maxWidth: 100 }}>
                          <InputItem
                            description={inputInitials[fieldKey].priority!}
                            validation={validation}
                            validationKey={keyPriority}
                          />
                        </Col>
                      </Row>
                    )
                  })}
              </div>
            </div>
            <div className={'mb-4 mt-4'}>
              <h4>{t('Groups')}</h4>
              {collectorsData?.map(({ group, collectors }) => {
                return (
                  <CollectorsGroup
                    key={group.id}
                    filterCollectorName={''}
                    setCollectorsByStage={setCollectorsByStage}
                    onChangeCollector={onChangeCollector}
                    selectedCollectors={validation.values.users}
                    items={collectors}
                    name={group.name}
                    id={String(group.id)}
                  />
                )
              })}
            </div>
            {!!errorGroups && <DangerAlert error={errorGroups} />}
            {!!errorGroupCollectors && (
              <DangerAlert error={errorGroupCollectors} />
            )}
            {!!errorCreateCallList && (
              <DangerAlert error={errorCreateCallList} />
            )}
            {!!errorUpdateCallList && (
              <DangerAlert error={errorUpdateCallList} />
            )}
            <Button
              disabled={isLoadingCreateCallList || isLoadingUpdateCallList}
              className='text-nowrap mt-3 mb-3'
              color='primary'
              style={{ backgroundColor: '#405189' }}
              type={'submit'}
            >
              {id ? t('Update') : t('Create')}
            </Button>
          </form>
        </CardBody>
      </Card>
    </div>
  )
}
