import { TFunction } from 'i18next'
import * as Yup from 'yup'
import { PhonesPriority } from 'src/services/calling'

const dayIndexes = ['0', '1', '2', '3', '4', '5', '6']

export const getCallListValidationSchema = (t: TFunction) => {
  const dayValidation = Yup.number()
    .typeError(t('Required'))
    .min(0, t('Value_must_be_between_0_and_6'))
    .max(6, t('Value_must_be_between_0_and_6'))
    .required(t('Required'))

  const recallWeekdaysShape = dayIndexes.reduce(
    (acc, dayIndex) => ({
      ...acc,
      [dayIndex]: dayValidation
    }),
    {}
  )

  const isAnyFieldFilled = (fields: PhonesPriority) => {
    return Object.values(fields).some(
      (value) => value !== undefined && value !== null && String(value) !== ''
    )
  }

  return Yup.object({
    name: Yup.string().required(t('Required')),
    phones_priority: Yup.object({
      mobile: Yup.number()
        .nullable()
        .test(
          'required-if-other-fields-filled',
          t('Required'),
          function (value) {
            return isAnyFieldFilled({ ...this.parent, mobile: value })
              ? !!value
              : true
          }
        ),
      thirdparty: Yup.number()
        .nullable()
        .test(
          'required-if-other-fields-filled',
          t('Required'),
          function (value) {
            return isAnyFieldFilled({ ...this.parent, thirdparty: value })
              ? !!value
              : true
          }
        ),

      work: Yup.number()
        .nullable()
        .test(
          'required-if-other-fields-filled',
          t('Required'),
          function (value) {
            return isAnyFieldFilled({ ...this.parent, work: value })
              ? !!value
              : true
          }
        ),

      extra: Yup.number()
        .nullable()
        .test(
          'required-if-other-fields-filled',
          t('Required'),
          function (value) {
            return isAnyFieldFilled({ ...this.parent, extra: value })
              ? !!value
              : true
          }
        )
    }).nullable(),
    is_federal_law_limited: Yup.boolean().nullable(),
    is_active: Yup.boolean().nullable(),
    stages: Yup.array().nullable(),
    users: Yup.array().nullable(),
    sort_rules: Yup.object().nullable(),
    extra_settings: Yup.object().nullable(),
    recall_weekdays: Yup.object()
      .shape(recallWeekdaysShape)
      .required(t('Required'))
  })
}
