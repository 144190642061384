import React, { useState } from 'react'
import { Input, Label } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Collector } from 'src/services/calling'

type Props = {
  setCollectorsByStage: (value: Collector[]) => void
  filterCollectorName: any
  name: string
  id: string
  items: Collector[]
  selectedCollectors: Collector[]
  onChangeCollector: (value: Collector) => void
}

export const CollectorsGroup = ({
  setCollectorsByStage,
  filterCollectorName,
  items = [],
  name,
  id,
  selectedCollectors,
  onChangeCollector
}: Props) => {
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)

  const onToggleOpen = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    setOpened(!opened)
  }

  const isAllSelected = () => {
    if (items.length < 1) return false
    let result = true
    items.forEach((item) => {
      if (!selectedCollectors.find((collector) => collector.id === item.id)) {
        result = false
      }
    })
    return result
  }

  const onChangeAllCollectors = (checked: boolean) => {
    const newArray: Collector[] = []
    if (checked) {
      items.forEach((item) => {
        if (!selectedCollectors.find((collector) => item.id === collector.id)) {
          newArray.push(item)
        }
      })
      setCollectorsByStage([...selectedCollectors, ...newArray])
    } else {
      selectedCollectors.forEach((collector) => {
        if (!items.find((item) => item.id === collector.id)) {
          newArray.push(collector)
        }
      })
      setCollectorsByStage(newArray)
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '300px',
          justifyContent: 'space-between'
        }}
      >
        <Label
          style={{
            verticalAlign: 'baseline',
            display: 'block',
            lineHeight: '21px',
            fontSize: '14px',
            marginBottom: '10px'
          }}
          key={id}
          className='form-check-label'
        >
          <Input
            className='form-check-input'
            type='checkbox'
            disabled={items.length < 1}
            checked={isAllSelected()}
            id={id}
            style={{
              width: '21px',
              height: '21px',
              marginRight: '15px',
              cursor: 'pointer'
            }}
            onChange={(evv) => onChangeAllCollectors(evv.target.checked)}
          />
          <span>{name}</span>
        </Label>
        {items.length > 0 && (
          <span
            onClick={onToggleOpen}
            style={{ marginLeft: '20px', cursor: 'pointer' }}
          >
            {opened ? t('Hide') : t('Uncover')}
          </span>
        )}
        {items.length < 1 && (
          <span style={{ marginLeft: '20px', cursor: 'pointer' }}>
            {t('No users in group')}
          </span>
        )}
      </div>
      <div style={{ marginLeft: '35px' }}>
        {opened &&
          items
            .filter((item) =>
              `${item.surname} ${item.name} ${item.midname}`
                .toLowerCase()
                .includes(filterCollectorName)
            )
            .map((item) => (
              <Label
                style={{
                  verticalAlign: 'baseline',
                  display: 'block',
                  lineHeight: '21px',
                  fontSize: '14px',
                  marginBottom: '10px'
                }}
                key={`group-${item.id}`}
                className='form-check-label'
              >
                <Input
                  className='form-check-input'
                  type='checkbox'
                  checked={
                    !!selectedCollectors.find(
                      (collector) => collector.id === item.id
                    )
                  }
                  id={`group-${item.id}`}
                  style={{
                    width: '21px',
                    height: '21px',
                    marginRight: '15px',
                    cursor: 'pointer'
                  }}
                  onChange={() => onChangeCollector(item)}
                />
                <span>
                  {item.surname} {item.name} {item.midname}
                </span>
              </Label>
            ))}
      </div>
    </div>
  )
}
