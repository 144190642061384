import { Values } from '../../Queues/types/queues.types'
import { TableHeader } from '../types/callList.types'
import { TFunction } from 'i18next'

export const HEADERS: Record<string, TableHeader> = {
  id: {},
  name: { label: 'full_name' }
}
const booleanOptions = [
  { name: '-', value: '-' },
  { name: 'asc', value: 1 },
  { name: 'desc', value: -1 }
]

export const inputInitials: Record<string, Partial<Values>> = {
  order_income: {
    type: 'order',
    order: {
      label: 'order_income',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_login_date: {
    type: 'order',
    order: {
      label: 'order_login_date',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_promise_date: {
    type: 'order',
    order: {
      label: 'order_promise_date',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },

  order_day_past_due_promise: {
    type: 'order',
    order: {
      label: 'order_day_past_due_promise',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_prolongation_count: {
    type: 'order',
    order: {
      label: 'order_prolongation_count',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_loan_stage: {
    type: 'order',
    order: {
      label: 'order_loan_stage',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_collection_score: {
    type: 'order',
    order: {
      label: 'order_collection_score',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_dpd: {
    type: 'order',
    order: {
      label: 'order_dpd',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_amount_debt: {
    type: 'order',
    order: {
      label: 'order_amount_debt',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  },
  order_timezone: {
    type: 'order',
    order: {
      label: 'order_timezone',
      initial: '-',
      options: booleanOptions,
      isNumber: true
    },
    priority: { label: 'priority', initial: 0, isNumber: true }
  }
}

export const getPhoneTypes = (t: TFunction) => {
  return [
    {
      label: t('mobile_phone_number'),
      name: 'phones_priority.mobile'
    },
    {
      label: t('contact_person_phone'),
      name: 'phones_priority.thirdparty'
    },
    {
      label: t('work_phone'),
      name: 'phones_priority.work'
    },
    {
      label: t('Additional_phone_number'),
      name: 'phones_priority.extra'
    }
  ]
}
