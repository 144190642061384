import { CallList, Collector, Group, SortRules } from 'src/services/calling'
import { inputInitials } from '../constants/callList.constants'
import { FormValues } from '../types/callList.types'

const dayIndexes = ['0', '1', '2', '3', '4', '5', '6']

export const getInitialValues = (
  callListData?: CallList,
  collectorsData?: {
    group: Group
    collectors: Collector[]
  }[]
): FormValues => {
  const initialSortRules = Object.keys(inputInitials).reduce(
    (acc, fieldKey) => {
      const orderValue =
        callListData?.sort_rules?.[fieldKey as keyof SortRules]?.order
      acc[`sort_rules_${fieldKey}_order`] =
        orderValue !== undefined && orderValue !== null ? orderValue : '-'
      acc[`sort_rules_${fieldKey}_priority`] =
        callListData?.sort_rules?.[fieldKey as keyof SortRules]?.priority ?? 0
      return acc
    },
    {} as { [key: string]: number | string }
  )

  const mappedUsers: Collector[] = (callListData?.users || [])
    .map(
      (userId) =>
        collectorsData
          ?.flatMap(({ collectors }) => collectors)
          .find((collector) => collector.id === userId)
    )
    .filter((user): user is Collector => user !== undefined)

  const recallWeekdaysInitial: Record<string, number | null> = {}
  dayIndexes.forEach((index) => {
    recallWeekdaysInitial[index] =
      callListData?.recall_weekdays?.[String(index)] ?? null
  })

  return {
    name: callListData?.name ?? '',
    stages: callListData?.stages ?? [],
    users: mappedUsers ?? [],
    phones_priority: callListData?.phones_priority ?? null,
    is_federal_law_limited: callListData?.is_federal_law_limited ?? false,
    is_active: callListData?.is_active ?? false,
    sort_rules: callListData?.sort_rules ?? null,
    recall_weekdays: recallWeekdaysInitial,
    extra_settings: callListData?.extra_settings ?? null,
    ...initialSortRules
  }
}

export const constructSortRules = (
  values: FormValues
): Partial<SortRules> | null => {
  const sortRules = (Object.keys(inputInitials) as Array<keyof SortRules>)
    .filter((fieldKey) => inputInitials[fieldKey].type === 'order')
    .reduce((accumulator, fieldKey) => {
      const orderValue = values[`sort_rules_${fieldKey}_order`]
      const priorityValue = values[`sort_rules_${fieldKey}_priority`]
      if (orderValue === '-' || orderValue === undefined) {
        return accumulator
      }
      return {
        ...accumulator,
        [fieldKey]: {
          order: orderValue === '-' ? null : parseInt(orderValue, 10),
          priority: parseInt(priorityValue, 10) || 0
        }
      }
    }, {} as Partial<SortRules>)

  return Object.keys(sortRules).length > 0 ? (sortRules as SortRules) : null
}
