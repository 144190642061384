import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table
} from 'reactstrap'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import Loader from 'src/Components/Common/Loader'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import { EditPerson } from './EditPerson'
import { DeletePerson } from './DeletePerson'
import { EditSettingsCallResult } from './EditSettingsCallResult'
import { DeleteSettingCallResult } from './DeleteSettingsCallResult'
import EditReason from './EditReason'
import DeleteReason from './DeleteReason'
import { SetDefaultSettings } from './SetDefaultSettings'
import {
  useGetCallPersonsQuery,
  useGetCallSettingsQuery,
  useGetCallReasonsByProfileIdQuery
} from 'src/services/calling'

export const EditingCallResults = () => {
  const { t } = useTranslation()
  const [active, setActive] = useState(0)

  const fieldsDescription: any = {
    result_type: {
      label: 'Call result'
    },
    need_reason: {
      label: 'Is a reason required?',
      mappingValue: (value: boolean) => (value ? t('Yes') : t('No'))
    },
    need_promise_amount: {
      label: 'Is a promise amount required?',
      mappingValue: (value: boolean) => (value ? t('Yes') : t('No'))
    },
    need_promise_date: {
      label: 'Is a promise date required?',
      mappingValue: (value: boolean) => (value ? t('Yes') : t('No'))
    },
    need_comment: {
      label: 'Is a comment required?',
      mappingValue: (value: boolean) => (value ? t('Yes') : t('No'))
    },
    is_success: {
      label: 'Consider_it_successful',
      mappingValue: (value: boolean) => (value ? t('Yes') : t('No'))
    }
  }

  const {
    data: persons = [],
    isLoading: personsLoading,
    error: personsError
  } = useGetCallPersonsQuery()

  const activePerson = persons[active]

  const { data: settings = [], isLoading: settingsLoading } =
    useGetCallSettingsQuery(
      { person_id: activePerson?.type_id },
      {
        skip: !activePerson
      }
    )

  const { data: reasons = [], isLoading: reasonsLoading } =
    useGetCallReasonsByProfileIdQuery()

  const handleActive = (rank: number) => {
    setActive(rank)
  }

  const title = t('Editing call results')
  document.title = title
  const bread = <BreadCrumb title={title} />

  if (personsLoading) return <LoadingPage title={title} />

  if (personsError) {
    return <ErrorPage title={title} error={personsError} />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            <Nav tabs className='nav-tabs nav-border-top mb-3'>
              {persons.map((person, rank) => (
                <NavItem key={`nav-${person.id}-${rank}`}>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                      active: active === rank
                    })}
                    onClick={() => handleActive(rank)}
                  >
                    {person.name}
                  </NavLink>
                </NavItem>
              ))}
              <NavItem className='ms-auto'>
                <EditPerson />
              </NavItem>
              <NavItem className='px-1'>
                <EditPerson persons={persons} />
              </NavItem>
              <NavItem>
                <DeletePerson persons={persons} />
              </NavItem>
            </Nav>
            <TabContent activeTab={active}>
              {settingsLoading ? (
                <Loader />
              ) : (
                <TabPane tabId={active}>
                  <div className='table-responsive table-card my-1'>
                    {settings.length > 0 ? (
                      <Table
                        hover
                        className='table-sm align-middle table-nowrap mb-0'
                      >
                        <thead>
                          <tr>
                            {Object.keys(fieldsDescription).map((fieldName) => (
                              <th className='ps-3' key={fieldName} scope='col'>
                                {t(
                                  fieldsDescription[fieldName].label ||
                                    fieldName
                                )}
                              </th>
                            ))}
                            <th style={{ width: '1%' }}>{t('Actions')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {settings.map((entity) => (
                            <tr key={entity.id}>
                              {Object.keys(fieldsDescription).map(
                                (fieldName: string, index: number) => {
                                  const fieldDesc = fieldsDescription[fieldName]
                                  let value =
                                    entity[fieldName as keyof typeof entity]

                                  if (fieldDesc.mappingValue) {
                                    value = fieldDesc.mappingValue(value)
                                  }

                                  return (
                                    <td className='ps-3' key={fieldName}>
                                      {index === 0 &&
                                      entity.is_default &&
                                      entity.result_type ? (
                                        <>
                                          <i className='text-info ri-award-fill label-icon align-middle fs-16 me-2'></i>
                                          <span className='text-info'>
                                            {value}
                                          </span>
                                        </>
                                      ) : (
                                        value
                                      )}
                                    </td>
                                  )
                                }
                              )}
                              <td>
                                <div className='hstack gap-4 flex-nowrap'>
                                  <EditSettingsCallResult
                                    person={activePerson}
                                    settings={entity}
                                  />
                                  <DeleteSettingCallResult
                                    client_type={activePerson.name}
                                    result_type={entity.result_type}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <h5 style={{ marginLeft: '15px', marginBottom: '15px' }}>
                        {t('No call results')}
                      </h5>
                    )}
                  </div>
                  <div className='mt-3 pb-3 h-divider d-flex gap-2'>
                    <EditSettingsCallResult person={activePerson} />
                    <SetDefaultSettings settings={settings} />
                  </div>
                </TabPane>
              )}
            </TabContent>
            <div className='mt-3'>
              <h5>{t('Editing reasons')}</h5>
              {reasonsLoading ? (
                <Loader />
              ) : reasons.length > 0 ? (
                <Table
                  hover
                  className='table-sm align-middle table-nowrap mb-0'
                >
                  <thead>
                    <tr>
                      <th>{t('Reason')}</th>
                      <th style={{ width: '1%' }}>{t('Actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reasons.map((entity) => (
                      <tr key={entity.id}>
                        <td className='ps-3'>{entity.name}</td>
                        <td>
                          <div className='hstack gap-4 flex-nowrap'>
                            <EditReason reason={entity} />
                            <DeleteReason reason={entity} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>{t('No reasons')}</p>
              )}
              <EditReason className='mt-3' />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
