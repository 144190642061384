import { useEffect, useState } from 'react'
import {
  Collector,
  Group,
  useGetGroupsQuery,
  useLazyGetGroupCollectorsQuery
} from 'src/services/calling'

export const useLoadCollectors = () => {
  const [collectorsData, setCollectorsData] = useState<
    {
      group: Group
      collectors: Collector[]
    }[]
  >([])

  const [
    getGroupCollectors,
    { isLoading: groupCollectorsLoading, error: errorGroupCollectors }
  ] = useLazyGetGroupCollectorsQuery()

  const {
    data: groups,
    isLoading: groupsLoading,
    error: errorGroups
  } = useGetGroupsQuery()

  useEffect(() => {
    if (!groups || groups.length === 0) {
      setCollectorsData([])
      return
    }
    const fetchCollectors = async () => {
      try {
        const results = await Promise.all(
          groups.map((group) =>
            getGroupCollectors(group.id.toString()).unwrap()
          )
        )
        const mappedData = groups.map((group, index) => {
          return {
            group,
            collectors: results[index]
          }
        })
        setCollectorsData(mappedData)
      } catch (err) {
        console.error('Error loading collectors:', err)
      }
    }

    fetchCollectors()
  }, [groups, getGroupCollectors])

  const loading = groupsLoading || groupCollectorsLoading

  return { collectorsData, loading, errorGroups, errorGroupCollectors }
}
