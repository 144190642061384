import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { Button, Card, CardBody, Table } from 'reactstrap'
import MetaBar from 'src/Components/Common/MetaBar'
import { useTranslation } from 'react-i18next'
import {
  useDeleteCallListMutation,
  useGetCallListCsvMutation,
  useGetCallListsQuery,
  useReUploadCallListMutation
} from 'src/services/calling'
import ErrorPage, { DangerAlert } from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import model from 'src/model'
import React from 'react'
import { Link } from 'react-router-dom'
import { HEADERS } from './constants/callList.constants'

export const CallLists = () => {
  const { t } = useTranslation()
  const {
    data: callLists,
    error: callListError,
    isLoading: isLoadingCallLists
  } = useGetCallListsQuery()
  const [
    deleteCallList,
    { isLoading: isLoadingDeleteCallList, error: errorDeleteCallList }
  ] = useDeleteCallListMutation()
  const [
    reUpload,
    { isLoading: isLoadingReUploadCallList, error: errorReUploadCallList }
  ] = useReUploadCallListMutation()

  const [getCallListCsv, { error: errorCallListCsv }] =
    useGetCallListCsvMutation()

  const handleDeleteCallList = async (id: string) => {
    try {
      await deleteCallList(id).unwrap()
    } catch (error) {
      console.log(error)
    }
  }

  const handleReUploadCallList = async (id: string) => {
    try {
      await reUpload(id).unwrap()
    } catch (error) {
      console.log(error)
    }
  }

  const handleDownload = async (id: string) => {
    try {
      const blob = await getCallListCsv(id).unwrap()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Выгрузка из Call-листа.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (err) {
      console.error('Ошибка при загрузке файла:', err)
    }
  }

  const title = t('Formation_of_Call-lists')
  document.title = title

  if (callListError) {
    return <ErrorPage title={title} error={callListError} />
  }

  if (isLoadingCallLists) {
    return <LoadingPage title={title} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} />
      <Card>
        <CardBody>
          <MetaBar createLink={'/call-list-create'} />
          <Table hover className='table-sm align-middle table-nowrap mb-0'>
            <thead>
              <tr>
                <th />
                {Object.keys(HEADERS).map((header) => (
                  <th className='ps-3' key={header} scope='col'>
                    {t(HEADERS[header].label || header)}
                  </th>
                ))}
                <th scope='col'>{t('Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {callLists &&
                callLists.length > 0 &&
                callLists.map((entity) => {
                  return (
                    <tr key={entity.id}>
                      <td style={{ width: '1%' }}></td>
                      {Object.keys(HEADERS).map((fieldName) => {
                        if (fieldName === 'id')
                          return (
                            <td className='ps-3' key={fieldName}>
                              {entity.id}
                            </td>
                          )
                        return (
                          <td
                            className='ps-3'
                            key={fieldName}
                            style={{ whiteSpace: 'normal' }}
                          >
                            {model.fieldValueToText(fieldName, entity, HEADERS)}
                          </td>
                        )
                      })}
                      <td>
                        <div className='hstack gap-3 flex-wrap'>
                          <Link
                            to={`/call-list-edit?id=${entity.id}`}
                            className='link-success fs-15'
                          >
                            <i className='ri-edit-2-line'></i>
                          </Link>
                          <button
                            onClick={() =>
                              handleDeleteCallList(String(entity.id))
                            }
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent'
                            }}
                            className='link-danger fs-15'
                            disabled={isLoadingDeleteCallList}
                          >
                            <i className='ri-delete-bin-line'></i>
                          </button>
                          <Button
                            onClick={() =>
                              handleReUploadCallList(String(entity.id))
                            }
                            disabled={isLoadingReUploadCallList}
                            color={'primary'}
                            size={'sm'}
                          >
                            Перезапустить обзвон
                          </Button>
                          <Button
                            onClick={() => handleDownload(String(entity.id))}
                            disabled={isLoadingReUploadCallList}
                            color={'primary'}
                            size={'sm'}
                          >
                            {t('Export to CSV')}
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
          {errorDeleteCallList && (
            <div className={'mt-3'}>
              <DangerAlert error={errorDeleteCallList} />
            </div>
          )}
          {errorReUploadCallList && (
            <div className={'mt-3'}>
              <DangerAlert error={errorReUploadCallList} />
            </div>
          )}
          {errorCallListCsv && (
            <div className={'mt-3'}>
              <DangerAlert error={errorCallListCsv} />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
